import { AxiosInstance } from 'axios';
import BaseObjectEndpoint from '@services/axios/BaseObjectEndpoint';
import BaseModelEndpoint from '@services/axios/BaseModelEndpoint';
import { Base } from '@screen/models/Base';

export type EndpointPath<T, C> = ((id: number) => T) & C;

export type BaseEndpointConfig = {
  axiosInstance: AxiosInstance;
  url?: string;
};

export type BaseEndpointObjectConfig = BaseEndpointConfig & {
  id: number;
};

export type EndpointConfig<C extends typeof Base> = BaseEndpointConfig & {
  ModelConstructor?: C;
};

export default abstract class BaseEndpoint<C extends typeof Base> {
  protected readonly AXIOS_INSTANCE: AxiosInstance;
  protected readonly MODEL_CONSTRUCTOR: C;
  protected URL: string;

  protected constructor(config: Required<EndpointConfig<C>>) {
    this.AXIOS_INSTANCE = config.axiosInstance;
    this.MODEL_CONSTRUCTOR = config.ModelConstructor;
    this.URL = config.url;
  }

  static Add<
    O extends BaseObjectEndpoint<any, any>,
    M extends BaseModelEndpoint<any, any, any>
  >(
    ObjectEndpoint: new (config: BaseEndpointObjectConfig) => O,
    ModelEndpoint: new (config: BaseEndpointConfig) => M,
    endpointParams: BaseEndpointConfig
  ): EndpointPath<O, M> {
    const objectEndpointFunc = (id: number) => {
      return new ObjectEndpoint({ id, ...endpointParams });
    };

    return mergeFunAndObj(
      objectEndpointFunc,
      new ModelEndpoint({ ...endpointParams })
    );
  }
}

export function mergeFunAndObj<T>(func: any, obj: any): T {
  for (let o = obj; o !== Object.prototype; o = Object.getPrototypeOf(o)) {
    for (let key of Object.getOwnPropertyNames(o)) {
      if (key === 'constructor') continue;
      func[key] = o[key];
    }
  }

  return func;
}
