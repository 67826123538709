import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const DEFAULT_API_URL = 'http://localhost:8000';

let API_URL = process.env.REACT_APP_API_URL || DEFAULT_API_URL;

if (DEFAULT_API_URL === API_URL)
  console.warn(`WARN: using default API ${API_URL}`);
else console.log(`Found API url: ${API_URL}`);
// TODO: uncomment when the API is ready
// API_URL += "/api/v1";

const attachJwtTokenToRequests = (axios: AxiosInstance): void => {
  // Attach interceptor to add auth token to requests.
  axios.interceptors.request.use(
    async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      const apiToken = 'ssss'; //await getAPIToken();
      if (apiToken != null) {
        config.headers.Authorization = `Bearer ${apiToken}`;
      }
      config.headers['Access-Control-Allow-Origin'] = '*';
      return config;
    }
  );
};

const axiosInstance = axios.create({
  baseURL: API_URL,
});
attachJwtTokenToRequests(axiosInstance);

export default axiosInstance;
