import { Base, BaseArray } from './Base';
import { Levels } from './Levels';
import { Meetings } from './Meetings';

export class RoomsArray extends BaseArray<Rooms> {}

export class Rooms extends Base {
  levelId?: number;
  level?: Levels;
  meetings?: Array<Meetings>;
  meetingsId?: Array<number>;
  constructor(values?: Partial<Rooms>) {
    super(values);
    if (!values) return;
    this.levelId = values.levelId;
    this.level = new Levels(values.level);
    this.meetings = values.meetings?.map((m) => new Meetings(m));
    this.meetingsId = values.meetingsId;
  }
}
