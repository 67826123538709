import { Col, Row } from '@design';
import { Levels } from '@screen/models/Levels';
import React from 'react';
import RoomComponent from '../RoomComponent/RoomComponent';
import styles from './LevelComponent.module.css';

type LevelComponentProps = {
  level: Levels;
};
const LevelComponent: React.FC<LevelComponentProps> = ({ level }) => {
  return (
    <div>
      <Row gutter={2}>
        <Col className="gutter-row" span={3}>
          <div className={styles.level}>{level.name}</div>
        </Col>
        <Col className="gutter-row" span={7}>
          <div className={styles.level} />
        </Col>
        <Col className="gutter-row" span={7}>
          <div className={styles.level} />
        </Col>
        <Col className="gutter-row" span={7}>
          <div className={styles.level} />
        </Col>
      </Row>
      {level.rooms?.map((r) => (
        <Row key={r.key}>
          <Col span={24} style={{ ...styles }} key={r.key}>
            <RoomComponent room={r} key={r.key} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default LevelComponent;
