import { Col } from '@design';
import React from 'react';

import styles from './LeftComponent.module.css';

const LeftComponent: React.FC = () => (
  <Col span={10}>
    <div className={styles.leftComponent}>
      <div>
        <p>TODAY'S</p>
        <p>MEETING</p>
      </div>
    </div>
  </Col>
);

export default LeftComponent;
