import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import SiteLayout from './modules/core/base/layout/SiteLayout';
import { URL_PATH } from '@mypp-url';
import MainScreenPage from '@screen/pages/MainScreenPage/MainScreenPage';

function App() {
  return (
    <SiteLayout>
      <Switch>
        <Route exact path={URL_PATH.HOME}>
          <div className="App">
            <MainScreenPage />
          </div>
        </Route>
      </Switch>
    </SiteLayout>
  );
}

export default App;
