import React from 'react';
import ContentContainer from './ContentContainer';
import styled from 'styled-components';
import { Layout } from '../../design';

const TopLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;

type SiteLayoutProps = {
  children: Array<JSX.Element> | JSX.Element;
};

function SiteLayout({ children, ...props }: SiteLayoutProps) {
  return (
    <TopLayout>
      <Layout className="site-layout">
        <ContentContainer>{children}</ContentContainer>
      </Layout>
    </TopLayout>
  );
}
export default SiteLayout;
