import { Base, BaseArray } from './Base';
import { Rooms } from './Rooms';

export class LevelsArray extends BaseArray<Levels> {}

export class Levels extends Base {
  rooms?: Array<Rooms>;
  roomsId?: Array<number>;
  constructor(values?: Partial<Levels>) {
    super(values);
    if (!values) return;
    this.rooms = values.rooms?.map((r) => new Rooms(r));
  }

  static Array = LevelsArray;
}
