import React from 'react';
import { Layout as AntLayout } from 'antd';

const {
  Header: AntHeader,
  Footer: AntFooter,
  Sider: AntSider,
  Content: AntContent,
} = AntLayout;

// ------------------- Layout ------------------------
// Wrapper
export interface LayoutProps extends React.ComponentProps<typeof AntLayout> {}
export function Layout(props: LayoutProps) {
  return <AntLayout {...props} />;
}

// ------------------- Header ------------------------

// Wrapper
export interface HeaderProps extends React.ComponentProps<typeof AntHeader> {}
Layout.Header = (props: HeaderProps) => {
  return <AntHeader {...props} />;
};

// ------------------- Sider --------------------------

// Wrapper
export interface SiderProps extends React.ComponentProps<typeof AntSider> {}

function Sider(props: SiderProps) {
  return <AntSider {...props} />;
}

Layout.Sider = Sider;

// ------------------- Content --------------------------

// Wrapper
export interface ContentProps extends React.ComponentProps<typeof AntContent> {}
Layout.Content = (props: ContentProps) => {
  return <AntContent {...props} />;
};

// ------------------- Footer ----------------------------

// Wrapper
export interface FooterProps extends React.ComponentProps<typeof AntFooter> {}
Layout.Footer = (props: FooterProps) => {
  return <AntFooter {...props} />;
};
