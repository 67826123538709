import { Base } from './Base';
import { Moment } from 'moment';
import { Rooms } from './Rooms';
import moment from 'moment';
import 'moment-timezone';

export class Meetings extends Base {
  startTime?: Moment;
  endTime?: Moment;
  subject?: string;
  organizer?: string;
  room?: Rooms;
  roomId?: number;

  constructor(values?: Partial<Meetings>) {
    super(values);
    if (!values) return;
    this.id = values.id;
    this.startTime = moment.utc(values.startTime).local();
    this.endTime = moment.utc(values.endTime).local();
    this.subject = values.subject;
    this.organizer = values.organizer;
    this.room = new Rooms(values);
    this.roomId = values.roomId;
  }

  static DATE_FORMAT = 'h:mm a';

  getStartTime(): string {
    return this.startTime
      ? this.startTime.format(Meetings.DATE_FORMAT)
      : 'NOT SET';
  }

  getEndTime(): string {
    return this.endTime ? this.endTime.format(Meetings.DATE_FORMAT) : 'NOT SET';
  }
}
