import { Col, Row } from '@design';
import { Levels } from '@screen/models/Levels';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import LevelComponent from '../LevelComponent/LevelComponent';
import style from './RightComponent.module.css';
import { Typography } from 'antd';
import endpoint from '@services/axios/Endpoint';
import { useWindowHeight } from '@react-hook/window-size';

const { Title } = Typography;

type RightComponentProps = {
  reloadData: any; //TODO: is a number. Represents the minutes.
  startDate: any; //TODO: is a date.
  endDate: any; //TODO: is a date.
  token: any; //TODO: is a string.
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};
const RightComponent: React.FC<RightComponentProps> = ({
  isLoading,
  setIsLoading,
  reloadData,
  startDate,
  endDate,
  token,
}) => {
  const [levels, setLevels] = useState<Array<Levels>>();

  const getter = useCallback(async (mounted = true) => {
    try {
      !isLoading && setIsLoading(true);
      const levels = await endpoint.levels.levels.get(undefined, {
        startDate: startDate,
        endDate: endDate,
        timeCheck: false,
      });

      console.log(
        `Meetings`,
        levels
          .map((l) =>
            l?.rooms?.map((r) =>
              r?.meetings?.map((m) => `${m.startTime} - ${m.endTime}`)
            )
          )
          .flat(3)
      );
      console.log('---------------------------------');
      console.log(reloadData + ' minutes to update meetings');

      setLevels([...levels]);
      if (!mounted) return;
    } catch (e) {
      console.error(`Error`, e);
      setLevels([]);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function timedCall(reloadData: number) {
    let miliseconds = 10000;
    if (!Number.isNaN(reloadData)) {
      miliseconds = reloadData * 60000;
    }

    return miliseconds;
  }

  useEffect(() => {
    let mounted = true;
    let time = timedCall(reloadData);

    (async () => {
      await getter(mounted);

      setInterval(getter, time);
    })();

    return () => {
      mounted = false;
    };
  }, [getter, reloadData]);

  const height = useWindowHeight();

  return (
    <Col className={style.RightComponent} span={14}>
      {!levels || levels.length === 0 ? (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col span={24}>
            <Title style={{ textAlign: 'center' }}>NO DATA AVAILABLE</Title>
          </Col>
        </Row>
      ) : (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
          <marquee
            direction="up"
            style={{
              height,
            }}
          >
            <div>
              {levels.map((l) => (
                <div key={l.key}>
                  <LevelComponent key={l.key} level={l} />
                </div>
              ))}
            </div>
          </marquee>
        </>
      )}
    </Col>
  );
};

export default RightComponent;
