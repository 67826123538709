import React, { useState } from 'react';
import styles from './TimerComponent.module.css';
import moment from 'moment';
const TimerComponent: React.FC = () => {
  const [currentTime, setCurrentTime] = useState<string>();

  const refreshTime = () => {
    const time = moment();
    setCurrentTime(time.format('hh:mm A'));
  };
  setInterval(refreshTime, 1000);
  return <div style={{ ...styles }}>{currentTime}</div>;
};

export default TimerComponent;
