import React from 'react';
import 'antd/dist/antd.css';
import styles from './MainScreenPage.module.css';
import HeaderComponent from '@screen/components/HeaderComponent/HeaderComponent';
import MainComponent from '@screen/components/MainComponent/MainComponent';

const MainScreenPage: React.FC<any> = () => {
  return (
    <div className={styles.MainScreenPage}>
      <HeaderComponent />
      <MainComponent />
    </div>
  );
};

export default MainScreenPage;
