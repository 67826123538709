import { Col, Row } from '@design';
import { Rooms } from '@screen/models/Rooms';
import React from 'react';
import MeetingComponent from '../MeetingComponent/MeetingComponent';

import style from './RoomComponent.module.css';

type RoomComponentProps = {
  room: Rooms;
};

const RoomComponent: React.FC<RoomComponentProps> = ({ room }) => (
  <div style={{ marginBottom: '2px' }}>
    {room.meetings?.map((m, i) =>
      i === 0 ? (
        <Row key={m.key}>
          <Col style={{ marginTop: '2px', marginBottom: '2px' }} span={3}>
            <div
              className={style.roomTile}
              style={{
                paddingLeft: '5px',
                fontFamily: 'Arial',
                fontSize: 'x-large',
                fontWeight: 'bold',
              }}
            >
              {room.name}
            </div>
          </Col>
          <Col
            style={{ marginTop: '2px', marginBottom: '2px' }}
            span={21}
            key={m.key}
          >
            <MeetingComponent meeting={m} key={m.key} />
          </Col>
        </Row>
      ) : (
        <Row style={{ marginBottom: '2px' }} key={m.key}>
          <Col span={3}>
            <div className={style.roomTile} />
          </Col>
          <Col span={21} key={m.key}>
            <MeetingComponent meeting={m} key={m.key} />
          </Col>
        </Row>
      )
    )}
  </div>
);

export default RoomComponent;
