import { Base, BaseArray } from '@screen/models/Base';
import BaseEndpoint, { EndpointConfig } from './BaseEndpoint';
import { DateParameters } from './parameters/DateParameters';
import moment from 'moment';
import qs from 'qs';

type EndpointModelConfig<M, C extends typeof Base, A> = EndpointConfig<C> & {
  ModelConstructor?: C & { Array: new (...array: M[]) => A };
};

export default abstract class BaseModelEndpoint<
  Model extends Base,
  Constructor extends typeof Base,
  Array extends BaseArray<Model>
> extends BaseEndpoint<Constructor> {
  MODEL_ARRAY_CONSTRUCTOR?;

  protected constructor(
    config: Required<EndpointModelConfig<Model, Constructor, Array>>
  ) {
    super(config);
    this.MODEL_ARRAY_CONSTRUCTOR = config.ModelConstructor?.Array;
  }

  async get(
    params?: Partial<Model>,
    dateParams?: DateParameters
  ): Promise<Array> {
    const response = await this.AXIOS_INSTANCE.get(this.URL, {
      params: { ...params, ...dateParams },
      paramsSerializer: (params) => {
        for (const [key, value] of Object.entries(params)) {
          if (value instanceof moment)
            params[key] = (value as any).format('YYYY-MM-DDTHH:mm:ss');
        }
        return qs.stringify(params);
      },
    });

    let models: Array = response.data.map(
      (d: Model) => new this.MODEL_CONSTRUCTOR(d)
    );

    if (this.MODEL_ARRAY_CONSTRUCTOR) {
      return new this.MODEL_ARRAY_CONSTRUCTOR(...models);
    }

    return models;
  }

  async getFirst(): Promise<Model | undefined> {
    const response = await this.AXIOS_INSTANCE.get(this.URL);

    if (response.data.length) {
      return new this.MODEL_CONSTRUCTOR(response.data[0]) as Model;
    }

    return undefined;
  }

  async create(data: Model): Promise<Model> {
    let { key, id, meta, ...postData } = data;
    if (data.meta?.createExcludedProps) {
      postData = { ...postData };
      data.meta.createExcludedProps.forEach((p) => delete (postData as any)[p]);
    }

    const response = await this.AXIOS_INSTANCE.post(this.URL, postData);
    return new this.MODEL_CONSTRUCTOR(response.data) as Model;
  }
}
