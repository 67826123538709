import BaseObjectEndpoint, {
  EndpointObjectConfig,
} from '@services/axios/BaseObjectEndpoint';
import { EndpointConfig } from '@services/axios/BaseEndpoint';
import BaseModelEndpoint from '@services/axios/BaseModelEndpoint';
import { Levels, LevelsArray } from '@screen/models/Levels';

const END_PATH = '/meetings/level';
const URL = `${END_PATH}`;

export class LevelsObjectEndpoint extends BaseObjectEndpoint<
  Levels,
  typeof Levels
> {
  constructor(config: EndpointObjectConfig<typeof Levels>) {
    super({
      ...config,
      url: URL,
      ModelConstructor: Levels,
    });
  }
}

export class LevelsModelEndpoint extends BaseModelEndpoint<
  Levels,
  typeof Levels,
  LevelsArray
> {
  constructor(config: EndpointConfig<typeof Levels>) {
    super({
      ...config,
      ModelConstructor: Levels,
      url: URL,
    });
  }
}
