import BaseEndpoint from '@services/axios/BaseEndpoint';
import axiosInstance from '@services/axios/axios';
import { LevelsModelEndpoint, LevelsObjectEndpoint } from './LevelsEndpoint';

const LevelsEndpointParams = {
  axiosInstance: axiosInstance,
};

export default class MainEndpoint {
  // Levels
  levels = BaseEndpoint.Add<LevelsObjectEndpoint, LevelsModelEndpoint>(
    LevelsObjectEndpoint,
    LevelsModelEndpoint,
    LevelsEndpointParams
  );
}
