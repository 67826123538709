import { Base } from '@screen/models/Base';
import BaseEndpoint, { EndpointConfig } from './BaseEndpoint';

export type EndpointObjectConfig<C extends typeof Base> = EndpointConfig<C> & {
  id: number;
};

export default abstract class BaseObjectEndpoint<
  Model extends Base,
  Constructor extends typeof Base
> extends BaseEndpoint<Constructor> {
  protected readonly ID: number;

  protected constructor(config: Required<EndpointObjectConfig<Constructor>>) {
    super(config);
    this.ID = config.id;
    this.attachUrlId();
  }

  private attachUrlId(): void {
    let idUrl = `/${this.ID}`;
    this.URL += idUrl;
  }

  async get(params?: Model): Promise<Model> {
    // TODO implement GET search params
    const response = await this.AXIOS_INSTANCE.get(this.URL);
    return new this.MODEL_CONSTRUCTOR(response.data) as Model;
  }

  async update(data: Model): Promise<Model> {
    const { key, meta, ...updateData } = data;

    if (data.meta?.updateExcludedProps) {
      data.meta.updateExcludedProps.forEach(
        (p) => delete (updateData as any)[p]
      );
    }

    try {
      const response = await this.AXIOS_INSTANCE.put(this.URL, updateData);
      return new this.MODEL_CONSTRUCTOR(response.data) as Model;
    } catch (e) {
      console.error(`The PUT request for ${this.URL} failed:`, e);
      throw e;
    }
  }

  async delete(): Promise<Model> {
    try {
      return this.AXIOS_INSTANCE.delete(this.URL);
    } catch (e) {
      console.error(`The DELETE request for ${this.URL} failed:`, e);
      throw e;
    }
  }
}
