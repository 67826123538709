import { Col, Row } from '@design';
import { Meetings } from '@screen/models/Meetings';
import React from 'react';

import styles from './MeetingComponent.module.css';

type MeetingComponentProps = {
  meeting: Meetings;
};
const MeetingComponent: React.FC<MeetingComponentProps> = ({ meeting }) => {
  return (
    <>
      <Row gutter={2}>
        <Col className="gutter-row" span={8}>
          <div className={styles.meetingTile}>
            {meeting.getStartTime() + ' - ' + meeting.getEndTime()}
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className={styles.meetingTile}>
            {meeting.subject || 'NOT SET'}
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className={styles.meetingTile}>
            {meeting.organizer || 'NOT SET'}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MeetingComponent;
