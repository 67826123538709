export abstract class BaseArray<T extends Base> extends Array<T> {}

type MetaType = {
  createExcludedProps?: string[];
  updateExcludedProps?: string[];
};

export class Base {
  id?: string;
  name?: string;
  key?: string;
  meta?: MetaType;

  constructor(values?: Partial<Base>) {
    if (!values) return;
    this.id = values.id;
    this.name = values.name;
    this.key =
      values?.key ||
      (this.id
        ? this.id.toString()
        : (Math.floor(Math.random() * 99999999) + 1).toString());
    this.meta = values.meta;
  }
}
