import React from 'react';
import styled from 'styled-components';
import { Layout } from '../../design/layout';

// Styles
const StyledContent = styled(Layout.Content)``;

type SiteContentProps = {
  children: string | JSX.Element | Array<JSX.Element>;
};
function ContentContainer({ children }: SiteContentProps) {
  return <StyledContent>{children}</StyledContent>;
}

export default ContentContainer;
