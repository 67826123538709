import { Col, Row } from '@design';
import React from 'react';
import TimerComponent from '../TimerComponent/TimerComponent';
import styles from './HeaderComponent.module.css';

const HeaderComponent: React.FC = () => {
  return (
    <Row
      className={styles.header}
      gutter={[0, 5]}
      justify="space-around"
      align="middle"
    >
      <Col span={8}>
        <div>
          <img
            width="200"
            src="https://gitlab.com/singletonsd/5-faces/slhd/apps/nesa/uploads/83dc92150155ca07cd017e1f2fadaa13/NESA_Logo.png"
            alt=""
          />
        </div>
      </Col>
      <Col span={8}>
        <div style={{ color: '#ffffff', textAlign: 'center' }}>Welcome</div>
      </Col>
      <Col span={8}>
        <div style={{ textAlign: 'right' }}>
          <TimerComponent />
        </div>
      </Col>
    </Row>
  );
};

export default HeaderComponent;
