import { Row } from '@design';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import LeftComponent from '../LeftComponent/LeftComponent';
import RightComponent from '../RightComponent/RightComponent';
import styles from './MainComponent.module.css';
import moment from 'moment';
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

const MainComponent: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [query] = useQueryParams({
    reloadData: NumberParam,
    startDate: DateParam,
    endDate: DateParam,
    token: StringParam,
  });

  const {
    reloadData = 15,
    startDate = moment(),
    endDate = moment(),
    token = '',
  } = query;

  function getParams() {
    console.info(`Available parameters:`, Object.keys(query));
    console.info(`Got values from query parameters:`, query);
    console.info(`Using values`, { startDate, endDate, token, reloadData });
  }

  useEffect(() => {
    getParams();
  });

  return (
    <Spin
      spinning={isLoading}
      size="large"
      tip="Loading..."
      style={{ height: '100vh', width: '100%', textAlign: 'center' }}
    >
      <Row className={styles.table} gutter={10} style={{ height: '100%' }}>
        <LeftComponent />
        <RightComponent
          reloadData={reloadData}
          startDate={
            !moment(startDate).isValid() ||
            moment(startDate).isAfter(moment(endDate))
              ? moment().format('YYYY-MM-DD')
              : moment(startDate).format('YYYY-MM-DD')
          }
          endDate={
            !moment(endDate).isValid() ||
            moment(startDate).isAfter(moment(endDate))
              ? moment().format('YYYY-MM-DD')
              : moment(endDate).format('YYYY-MM-DD')
          }
          token={token}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </Row>
    </Spin>
  );
};

export default MainComponent;
