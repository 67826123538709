import React from 'react';
import { Col as AntCol, Row as AntRow } from 'antd';

export interface RowProps extends React.ComponentProps<typeof AntRow> {}
export function Row(props: RowProps) {
  return <AntRow {...props} />;
}

export interface ColProps extends React.ComponentProps<typeof AntCol> {}
export function Col(props: ColProps) {
  return <AntCol {...props} />;
}
